import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';
import { Language } from 'src/app/model/language';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {
  languages: Language[] = [];

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.translations.languages.subscribe(languages => {
      this.languages = languages;
    })
  }
}
