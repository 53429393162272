import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {
  @Input() network: any = null;
  
  constructor() { }

  ngOnInit() {
  }

}
