
export const skills = [

    // General
    "Linux", "Shell", "Windows",

    // Réseau
    "Filtrage ACL", "Proxy", "AD", "DNS", "Firewall",

    // DevOps
    "Git", "Docker", "Ansible", "Gitlab-CI",
    "Nginx", "Kubernetes", "Traefik", "Swarm", "CloudFoundry",

    // Développement frontend
    "Angular", "Vue", "Bootstrap", "Material",

    // Développement backend"
    "Python", "C++", "C", "Spring Framework"
]
