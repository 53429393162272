
export const skills = [

    // General
    "Linux", "Shell", "Windows E-Buro",

    // Réseau
    "Proxy",

    // DevOps
    "Docker", "Ansible", "Gitlab-CI", "CloudFoundry",

    // Développement frontend
    "Angular", "Vue", "Bootstrap", "Material",

    // Développement backend"
    "Python", "Java", "Spring Framework", "Flask"
]
