import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurriculumComponent } from './view/curriculum/curriculum.component';
import { SchoolComponent } from './widget/school/school.component';
import { JobComponent } from './widget/job/job.component';
import { JobsComponent } from './component/jobs/jobs.component';
import { SchoolsComponent } from './component/schools/schools.component';
import { HeaderComponent } from './component/header/header.component';
import { NetworksComponent } from './component/networks/networks.component';
import { ContactComponent } from './component/contact/contact.component';
import { NetworkComponent } from './widget/network/network.component';
import { HobbiesComponent } from './component/hobbies/hobbies.component';
import { SkillsComponent } from './component/skills/skills.component';
import { LanguagesComponent } from './component/languages/languages.component';
import { ProjectsComponent } from './component/projects/projects.component';
import { NavigationComponent } from './component/navigation/navigation.component';
import { LanguageComponent } from './widget/language/language.component';
import { CurriculumsComponent } from './view/curriculums/curriculums.component';
import { JobParserComponent } from './view/job-parser/job-parser.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    CurriculumComponent,
    SchoolComponent,
    JobComponent,
    JobsComponent,
    SchoolsComponent,
    HeaderComponent,
    NetworksComponent,
    ContactComponent,
    NetworkComponent,
    HobbiesComponent,
    SkillsComponent,
    LanguagesComponent,
    ProjectsComponent,
    NavigationComponent,
    LanguageComponent,
    CurriculumsComponent,
    JobParserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    //
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatChipsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
