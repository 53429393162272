
export const skills = [

    // General
    "Linux", "Shell", "Windows",

    // Réseau
    "Filtrage ACL", "Proxy", "AD", "DNS",

    // DevOps
    "Git", "Docker", "Ansible", "Gitlab-CI",
    "Kubernetes", "Traefik", "Nginx", "CloudFoundry", "Swarm",

    // Développement frontend
    "Angular", "Vue", "Bootstrap", "Material",

    // Développement backend"
    "Python", "NodeJS", "Java", "C++", "C", "Spring Framework", "Django", "Flask",

    // Développement mobile
    "Android", "Nativescript", "Kotlin", "Cordova"
]
