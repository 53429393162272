import { School } from 'src/app/model/school';

export const schools: School[] = [
    {
        name: "ENSSAT",
        description: "Ecole Nationale Superieure de Sciences Appliquées et de Technologie",
        diploma: "Ingénieur en informatique, multimédia et réseaux",
        address: "10 rue de Kerampont",
        zipcode: 22300,
        city: "Lannion",
        period: ["Septembre 2017", "Août 2020"],
        achievements: [
            "Expérience de management international.",
            "Développement d’une application web pour simuler le trafic routier.",
            "Pré-certifications Cisco, CCNA 1 à 4"
        ]
    },
    {
        name: "IUT de Lannion",
        description: "Institut Universitaire de Technologie",
        diploma: "DUT Réseau et Télécoms",
        address: "rue Edouard Branly",
        zipcode: 22300,
        city: "Lannion",
        period: ["Septembre 2016", "Août 2017"],
        achievements: [
            "Protocoles de routage, configuration de routeurs, switchs...",
            "Développement d’une application pour promouvoir la formation. (Android)"
        ]
    },
    {
        name: "Lycée François Rabelais",
        description: "Classe préparatoire scientifique (MPSI / MP)",
        diploma: "CPGE Scientifique (MPSI / MP)",
        address: "8 Rue Rabelais",
        zipcode: 22000,
        city: "Saint-Brieuc",
        period: ["Septembre 2013", "Juin 2016"],
        achievements: [
            "Participation à un atelier de recherche en mathématiques."
        ]
    }
];
