import { Component, OnInit } from '@angular/core';
import { Job } from 'src/app/model/job';
import { TranslationService } from 'src/app/service/curriculum.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  jobs: Job[] = [];

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    console.log('in job component');
    this.translations.jobs.subscribe((jobs:Job[])=>{
      console.log('in job subscriber');
      this.jobs = jobs;
      console.log(this.jobs);
    })
  }

}
