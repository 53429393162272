import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';

@Component({
  selector: 'app-hobbies',
  templateUrl: './hobbies.component.html',
  styleUrls: ['./hobbies.component.scss']
})
export class HobbiesComponent implements OnInit {
  title: string = "";
  hobbies: any = null;

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.translations.titles.subscribe(titles=>{
      console.log(titles);
      this.title = titles['hobbies'];
      console.log(this.title);
    })

    this.translations.hobbies.subscribe(hobbies=>{
      this.hobbies = hobbies;
    })
  }

}
