import { School } from 'src/app/model/school';

export const schools: School[] = [
    {
        name: "ENSSAT",
        description: "Ecole Nationale Superieure de Sciences Appliquées et de Technologie",
        diploma: "Ingénieur en informatique, multimédia et réseaux",
        address: "10 rue de Kerampont",
        zipcode: 22300,
        city: "Lannion",
        period: ["Septembre 2017", "Août 2020"],
        achievements: [
            "Formation de data-mining à Dublin (Python/Pandas/Tensorflow)",
            "Expérience de management international",
            "Développement d'une application Android de poker planning (IP multicast)",
            "Développement d’une application web pour simuler le trafic routier (Python)"
        ]
    },
    {
        name: "IUT de Lannion",
        description: "Institut Universitaire de Technologie",
        diploma: "DUT Réseaux et Télécoms",
        address: "rue Edouard Branly",
        zipcode: 22300,
        city: "Lannion",
        period: ["Septembre 2016", "Août 2017"],
        achievements: [
            "Protocoles de routage, configuration de routeurs, switchs...",
            "Développement d’une application Android pour promouvoir la formation."
        ]
    },
    {
        name: "Lycée Rabelais",
        description: "Classe préparatoire scientifique (MPSI / MP)",
        diploma: "CPGE Scientifique (MPSI / MP)",
        address: "8 Rue Rabelais",
        zipcode: 22000,
        city: "Saint-Brieuc",
        period: ["Septembre 2013", "Juin 2016"],
        achievements: []
    }
];
