
export const skills = [
    "Méthode agile", "Travail en équipe",

    // Développement backend"
    "Python", "C", "Django", "Flask", "REST", "OpenAPI",

    // DevOps
    "Git", "Docker", "Ansible", "Gitlab-CI",
    "Kubernetes", "Swarm",

    // General
    "Linux", "Bash",

    // Développement frontend
    "Angular", "Vue.js",

    // Réseau
    "Réseau", "Reverse Proxy", "DNS",
]
