import { applicant } from './applicant';
import { jobs } from './jobs';
import { schools } from './schools';
import { languages } from './languages';
import { projects } from './projects';
import { hobbies } from './hobbies';
import { skills } from './skills';
import { titles } from './titles';

export const DataFull = {
    title: "CV ultra-complet",
    applicant: applicant,

    jobs: jobs,
    projects: projects,
    schools: schools,

    skills: skills,
    hobbies: hobbies,
    languages: languages,

    titles: titles
}
