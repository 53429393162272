import { Component, OnInit, Input } from '@angular/core';
import { Job } from 'src/app/model/job';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit {
  @Input() job: Job = null;
  
  constructor() { }

  ngOnInit() {
  }

}
