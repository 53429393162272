import { DataDevopsCyber } from './devops-cyber'
import { DataFull } from './full'
import { DataAngularSpring } from './angular-spring-engineer'
import { DataProcessOwner } from './process-owner'
import { DataSpecialistCloudCommunications } from './specialiste-comm-cloud'
import { DataDevOps5GEdge } from './devops-5g-edge'
import { DataDevPython } from './dev-python'

export const LANGUAGES = {
    'devops-cyber': DataDevopsCyber,
    'full': DataFull,
    'angular-spring': DataAngularSpring,
    'process-owner': DataProcessOwner,
    'specialist-corporate-cloud-communications': DataSpecialistCloudCommunications,
    'devops-5g-edge': DataDevOps5GEdge,
    'dev-python': DataDevPython
}
