import { Component, OnInit } from '@angular/core';
import { LANGUAGES } from 'src/app/languages';
import { TranslationService } from 'src/app/service/curriculum.service';

@Component({
  selector: 'app-curriculums',
  templateUrl: './curriculums.component.html',
  styleUrls: ['./curriculums.component.scss']
})
export class CurriculumsComponent implements OnInit {
  languages: { title: string; path: string }[] = [];

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.languages = Object.keys(LANGUAGES).map(code => ({
      'title': LANGUAGES[code].title,
      'path': '/curriculum/' + code
    }))
  }

}
