export const hobbies = [
    {
        label: "Amateur de musique",
        achievements: []
    },
    {
        label: "Développement / algorithmie",
        achievements: [
            "Hackathon ENSSAT",
            "Concours Meilleur dev. de France"
        ]
    },
    {
        label: "Viet vo dao (art martial)",
        achievements: [
            "Champion de France 2013",
            "Dispense de cours"
        ]
    },
    {
        label: "Maths en Jeans",
        achievements: [
            "Finaliste du concours André parent"
        ]
    }
]
