import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projects: any[] = []

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.translations.projects.subscribe(projects=>{
      this.projects = projects;
    });
  }

}
