import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contact: any;

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.translations.applicant.subscribe(applicant=>{
      this.contact = applicant.contact;
    })
  }

}
