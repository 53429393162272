import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';

@Component({
  selector: 'app-networks',
  templateUrl: './networks.component.html',
  styleUrls: ['./networks.component.scss']
})
export class NetworksComponent implements OnInit {
  networks: any;

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.translations.applicant.subscribe(applicant=>{
      this.networks = applicant.networks;
    })
  }

}
