import { Job } from 'src/app/model/job';

export const jobs: Job[] = [
    {
        title: "Apprenti ingénieur DevOps",
        company: "Orange Business Services",
        period: ["septembre 2017", "août 2020"],
        achievements: [
            "Analyse / automatisation de processus métiers (scripting Python)",
            "Développement d'applications web (Angular, Spring-Framework)",
            "Développement de micro-services (Docker, Compose)",
            "Déploiement / intégration continue (Ansible, Gitlab)",
            "Tests automatisés d'applications web (Python, Selenium, Javascript)",
            "Tests unitaires d'applications (Junit, Mockito, pytest)"
        ]
    },
    {
        title: "Développeur web full stack",
        company: "STDM, Berrien",
        period: ["juin 2017", "août 2017"],
        achievements: [
            "Développement d’un système de pointage du personnel (RFID)",
            "Participation au développement d’un logiciel de GMAO",
            "Développement backend (PHP, PostgreSQL)",
            "Développement frontend (Angular)"
        ]
    }
];