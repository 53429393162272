import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-parser',
  templateUrl: './job-parser.component.html',
  styleUrls: ['./job-parser.component.scss']
})
export class JobParserComponent implements OnInit {
  inputText: string = "";

  constructor() { }

  ngOnInit() {
  }

  parseOffer() {
    console.log(this.inputText);
  }
}
