
export const skills = [

    // General
    "Linux", "Windows", "Shell/Bash",

    // Réseau
    "Proxy", "AD", "DNS",

    // DevOps
    "Git", "Docker", "Ansible", "Gitlab-CI",
    "Swarm", "Kubernetes", "Flask", "CloudFoundry",

    // Développement frontend
    "Web", "API",
    "Angular", "Vue", "Bootstrap", "Material",

    // Développement backend"
    "Python", "NodeJS", "Spring Framework", "Django"
]
