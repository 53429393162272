import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {
  skills: any[] = [];

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.translations.skills.subscribe(skills=>{
      this.skills = skills;
    })
  }

}
