import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CurriculumComponent } from './view/curriculum/curriculum.component';
import { CurriculumsComponent } from './view/curriculums/curriculums.component';
import { JobParserComponent } from './view/job-parser/job-parser.component';


const routes: Routes = [
  { path: 'jobs/parser', component: JobParserComponent },
  { path: 'curriculums', component: CurriculumsComponent },
  { path: 'curriculum/:lang', component: CurriculumComponent },
  { path: '**', redirectTo: '/curriculum/full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
