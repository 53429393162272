
export const titles = {
    jobs: 'EXPÉRIENCE PROFESSIONNELLE',
    projects: 'PROJETS, À TITRE PERSONNEL',
    schools: 'FORMATION',
    skills: 'COMPÉTENCES',
    hobbies: 'ACTIVITÉS ET DISTINCTIONS',
    networks: 'RÉSEAUX SOCIAUX',
    languages: 'LANGUES'
}
