import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';
import { Applicant } from 'src/app/model/applicant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  applicant: Applicant = null;

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.translations.applicant.subscribe(applicant=>{
      this.applicant = applicant;
    })
  }

}
