export const projects = [
    {
        name: "Play",
        period: ["Septembre 2019", "Février 2020"],
        short_description: "Application web de gestion de playlists",
        description: "Application web permettant une gestion originale de playlists. Elle s'interface avec Spotify ou Deezer pour importer / exporter vos playlists favorites."
    },
    {
        name: "Kryer",
        period: ["Novembre 2018", "Octobre 2019"],
        short_description: "Application d’aide à l’orientation pour les lycéens",
        description: "Développement d’une application web et mobile native permettant d’analyser la personnalité des étudiants dans le but de leur faire découvrir des métiers."
    }
]