
export const skills = [

    // General
    "Linux", "Shell", "Windows",

    // Réseau
    "Filtrage ACL", "Proxy", "AD", "DNS",

    // DevOps
    "Git", "Docker", "Ansible", "Gitlab-CI",
    "Kubernetes", "Traefik", "Nginx", "CloudFoundry", "Swarm", "Prometheus",

    // Développement frontend
    "Angular", "Vue",

    // Développement backend"
    "Python", "Java", "C++", "C", "Spring Framework", "Django", "Flask",
]
