import { Injectable, EventEmitter } from '@angular/core';
import { Job } from '../model/job';
import { School } from '../model/school';
import { LANGUAGES } from '../languages';
import { Applicant } from '../model/applicant';
import { Language } from '../model/language';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  _titlesSource = new BehaviorSubject<object>({});
  titles: Observable<object> = this._titlesSource.asObservable();

  _applicantSource = new BehaviorSubject<Applicant>(null);
  applicant: Observable<Applicant> = this._applicantSource.asObservable();

  _jobsSource = new BehaviorSubject<Job[]>([]);
  jobs: Observable<Job[]> = this._jobsSource.asObservable();

  _schoolsSource = new BehaviorSubject<School[]>([]);
  schools: Observable<School[]> = this._schoolsSource.asObservable();

  _languagesSource = new BehaviorSubject<Language[]>([]);
  languages: Observable<Language[]> = this._languagesSource.asObservable();

  _projectsSource = new BehaviorSubject<any[]>([]);
  projects: Observable<any[]> = this._projectsSource.asObservable();

  _skillsSource = new BehaviorSubject<any[]>([]);
  skills: Observable<any[]> = this._skillsSource.asObservable();

  _hobbiesSource = new BehaviorSubject<any>(null);
  hobbies: Observable<any> = this._hobbiesSource.asObservable();

  constructor() {

  }

  setLocale(countryCode: string) {
    const countryData = LANGUAGES[countryCode];

    this._titlesSource.next(countryData.titles);
    this._applicantSource.next(countryData.applicant);
    this._jobsSource.next(countryData.jobs);
    this._schoolsSource.next(countryData.schools);
    this._languagesSource.next(countryData.languages);
    this._projectsSource.next(countryData.projects);
    this._skillsSource.next(countryData.skills);
    this._hobbiesSource.next(countryData.hobbies);
  }
}
