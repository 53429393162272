import { Applicant } from "src/app/model/applicant";

export const applicant: Applicant = {
    firstName: "Sylvan",
    lastName: "LE DEUNFF",
    title: "Process Owner",
    subtitle: "Curieux et enthousiaste, j'aime relever des défis en équipe.",

    profiles: [
        "assets/profiles/profile1.png",
        "assets/profiles/profile2.png",
        "assets/profiles/profile3.png"
    ],

    contact: {
        email: "sledeunf@gmail.com",
        phoneNumber: "(+33)6.51.95.36.33",

        address: {
            street: "14 square amiral André Roux",
            zipcode: 35700,
            city: "Rennes"
        }
    },

    networks: [{
        network: "LinkedIn",
        url: "https://www.linkedin.com/in/sylvan-le-deunff/",
        alias: "sylvan-le-deunff",
        icon: ["fab", "fa-linkedin", "#0090C2"]
    },
    {
        network: "GitHub",
        url: "https://github.com/sledeunf",
        alias: "sledeunf",
        icon: ["fab", "fa-github", "black"]
    },
    {
        network: "GitLab",
        url: "https://gitlab.com/SylvanLDENSSAT",
        alias: "SylvanLDENSSAT",
        icon: ["fab", "fa-gitlab", "#E24329"]
    },
    {
        network: "Website",
        url: "http://portfolio.sylvan.ovh",
        alias: "sylvan.ovh",
        icon: ["fas", "fa-globe", "#283E4A"]
    }
    ]
}
