import { Job } from 'src/app/model/job';

export const jobs: Job[] = [
    {
        title: "Ingénieur développeur Python",
        company: "Orange Business Services",
        period: ["octobre 2020", "aujourd'hui"],
        achievements: [
            "Développement d'un contrôleur d'autorisations pour Kubernetes.",
            "Développement API pour automatiser le déploiement d'infrastructures dans un environnement VMWare.",
            "Mise en place de bonnes pratiques d'intégration / déploiement continu.",
            "Tests automatisés d'API avec pytest."
        ]
    },
    {
        title: "Apprenti ingénieur DevOps",
        company: "Orange Business Services",
        period: ["septembre 2017", "août 2020"],
        achievements: [
            "Analyse / automatisation de processus métiers (scripting Python)",
            "Développement d'applications web (Vue, Angular, Flask, Spring boot)",
            "Développement de micro-services (Docker, Swarm)",
            "Déploiement / intégration continue (Ansible, Gitlab)"
        ]
    },
    {
        title: "Développeur web full stack",
        company: "STDM, Berrien",
        period: ["juin 2017", "août 2017"],
        achievements: [
            "Participation au développement d’un logiciel de GMAO",
        ]
    }
];