import { Language } from '../../model/language';

export const languages: Language[] = [
    {
        code: 'fr',
        name: 'Français',
        level: 'Langue maternelle'
    },
    {
        code: 'en',
        name: 'Anglais',
        level: 'Niveau B2 (CLES 2)'
    }
]