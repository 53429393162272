import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';
import { School } from 'src/app/model/school';

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss']
})
export class SchoolsComponent implements OnInit {
  schools: School[] = [];

  constructor(private translations: TranslationService) { }

  ngOnInit() {
    this.translations.schools.subscribe(schools=>{
      this.schools = schools;
    })
  }

}
