import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/service/curriculum.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {

  constructor(private translations: TranslationService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selectLanguage(params['lang']);
    })
  }


  selectLanguage(countryCode: string) {
    if (!!countryCode) {
      this.translations.setLocale(countryCode);
    }
  }

}
